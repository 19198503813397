// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', 'sans-serif';
  background-color: #020e18;
  // background-color: #fff;
  color: #4f5a5e;
}
