.main-content {
  margin-bottom: 50px;
  // background-color: #4f5a5e;
}

.grid-movie-title {
  display: grid;
  grid-template-areas: 'movieType . paginate';
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: 1fr;
  margin-bottom: 30px;
  font-size: 18px;
  color: #ffffff;
  width: inherit;

  .movieType {
    grid-area: movieType;
    text-align: left;
    padding: 8px 25px;
    color: #fff;
  }

  .paginate {
    grid-area: paginate;
    text-align: right;
    padding-right: 25px;
  }
}
