.about-container {
  padding-top: 90px;
  padding-bottom: 90px;
  width: 90%;
  margin: 0 auto;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  overflow-x: hidden;
  @media (min-width: 850px) and (max-width: 1111px) {
    flex-direction: column;
    margin-bottom: 90px;
    padding: 0;
    overflow-x: hidden;
  }
  @media (min-width: 501px) and (max-width: 850px) {
    flex-direction: column;
    margin-bottom: 90px;
    padding: 0;
    overflow-x: hidden;
  }
  @media (min-width: 0px) and (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 90px;
    padding: 0;
    overflow-x: hidden;
  }

  .catchphrase {
    font-style: italic;
  }

  .about-img {
    width: 90%;
    height: 280px;
    border-radius: 4px;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 850px) and (max-width: 1111px) {
      height: 490px;
      margin-top: 60px;
    }
    @media (min-width: 501px) and (max-width: 850px) {
      height: 372px;
      margin-top: 60px;
    }

    @media (min-width: 0px) and (max-width: 500px) {
      height: 265px;
      margin-top: 40px;
    }
  }

  .text-container {
    color: #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: 850px) and (max-width: 1111px) {
      text-align: center;
      align-items: center;
      height: 240px;
      justify-content: space-evenly;
    }

    @media (min-width: 501px) and (max-width: 850px) {
      text-align: center;
      align-items: center;
      height: 300px;
      justify-content: space-evenly;
    }
    @media (min-width: 0px) and (max-width: 500px) {
      text-align: center;
      align-items: center;
      height: 300px;
      justify-content: space-evenly;
    }
  }

  .about-copy {
    margin-right: 25%;
    text-align: left;
    @media (min-width: 850px) and (max-width: 1111px) {
      margin: 0;
      width: 70%;
    }
    @media (min-width: 501px) and (max-width: 850px) {
      margin: 0;
      width: 70%;
      font-size: 1.1rem;
    }
    @media (min-width: 0px) and (max-width: 500px) {
      margin: 0;
      width: 70%;
      font-size: 1.1rem;
    }
  }
}
