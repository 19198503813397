.tabs {
  text-align: left;
}

.tab-list {
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #abb7c4;
  padding-bottom: 15px;
  margin-right: 50px;
  &:hover {
    cursor: pointer;
  }
}

.tab-list-active {
  color: #d18f09;
  border-bottom: 3px solid #d18f09;
}

@media (max-width: 700px) {
  .tab-list {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .tab-list-item {
    display: block;
    text-align: left;
  }

  .tab-list-active {
    color: #d18f09;
    border-bottom: none;
  }
}
