.paginate-button {
  // background-color: #dd003f;
  background-color: #f7bc45;
  padding: 8px 15px;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  margin: 0 15px;
  outline: none !important;
}

.disable {
  cursor: none;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.5);
}

.pageCount {
  // color: #4f5a5e;
  color: #fff;
  margin: 0 5px;
  padding: 10px 5px;
}
