.star-rating {
  display: flex;
}

.back-stars {
  display: flex;
  color: #bdbdbd;
  position: relative;
}

.front-stars {
  display: flex;
  color: #f7bc45;
  position: absolute;
  overflow: hidden;
  top: 0;
}
